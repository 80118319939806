import {
  CellBoardConfig,
  CellBoardConfigInfo,
  CellBoardData,
  CellBoardMessageDetailDto,
  CellBoardMessageAttachmentDto,
  CellBoardMessageCreate,
  CellBoardMessageInfo,
  CellBoardSafetyMessage,
  CellBoardSafetyTopic,
  CellBoardEnabledMCellInfo,
  CellBoardSummaryFilter,
  CellBoardSummaryDto,
  CellBoardRosterInventoryCenterConfig,
  CellBoardDefaultSafetyMessage,
  CellBoardMCellTaskDto,
  CellBoardMCellTaskFilter,
  CellBoardMCellTaskReport,
  ProductionMetricIntervalDto,
  ProductionMetricIntervalFilter,
  CellBoardMessageDto,
  CellBoardMCellMessageDetailDto,
  CellBoardMessageFilter,
  CellBoardMessageLinkDto,
  CellBoardMessageLinkAddInfo,
  CellBoardMessageLinkUpdateInfo,
  CellBoardMetricReportFilter,
  CellBoardMetricReport,
  CellBoardSummaryHistoryDto,
  CellBoardSummaryHistoryFilter,
  CellBoardProductionMetricDto,
  MCellMessageCountResult,
} from './types';
import axiosInstance from '../../api';
import { Shift } from '../resourceTracking/types';

/**
 * API call to get count of Cell Board messages based on the provided information.
 * Used to determine if the duration of the message is valid.
 *
 * @param {CellBoardMessageInfo} cellBoardMessageInfo - The information about the Cell Board messages.
 * @returns {Promise<MCellMessageCountResult>} - A promise that resolves to the count of Cell Board messages.
 */
export const calculateCellBoardMessageCount = async (
  cellBoardMessageInfo: CellBoardMessageInfo,
): Promise<MCellMessageCountResult> => {
  const response = await axiosInstance.post<MCellMessageCountResult>(
    '/api/cellBoard/messageCount',
    cellBoardMessageInfo,
  );
  return response.data;
};

/**
 * API call to create a new Cell Board Message
 * @param cellBoardMessageCreate
 */
export const createCellBoardMessage = async (
  cellBoardMessageCreate: CellBoardMessageCreate,
): Promise<CellBoardMessageDetailDto> => {
  const { attachments, ...cellBoardMessageInfo } = cellBoardMessageCreate;

  const response = await axiosInstance.post<CellBoardMessageDetailDto>(
    '/api/cellBoard/message',
    cellBoardMessageInfo,
  );
  await addAttachmentsToCellBoardMessage({
    messageId: response.data.id,
    files: attachments,
  });

  return response.data;
};

/**
 * API call to get list of Cell Board messages for all users
 */
export const getCellBoardMessagesGlobal = async (
  filter: CellBoardMessageFilter,
): Promise<CellBoardMessageDto[]> => {
  const response = await axiosInstance.get('/api/cellBoard/messageGlobal', {
    params: filter,
  });

  return response.data;
};

/**
 * API call to get list of Cell Board messages for the current user
 */
export const getCellBoardMessages = async (
  filter: CellBoardMessageFilter,
): Promise<CellBoardMessageDto[]> => {
  const response = await axiosInstance.get('/api/cellBoard/message', {
    params: filter,
  });

  return response.data;
};

/**
 * API call to get a Cell Board Message by id
 * @param id
 */
export const getCellBoardMessage = async (
  id: string,
): Promise<CellBoardMessageDetailDto> => {
  const response = await axiosInstance.get(`/api/cellBoard/message/${id}`);
  return response.data;
};

/**
 * API call to update all Cell Board Message fields (except attachments and tasks)
 * @param params
 */
export const updateCellBoardMessage = async (params: {
  id: string;
  cellBoardMessageInfo: CellBoardMessageInfo;
}): Promise<CellBoardMessageDetailDto> => {
  const response = await axiosInstance.post(
    `/api/cellBoard/message/${params.id}`,
    params.cellBoardMessageInfo,
  );
  return response.data;
};

/**
 * API call to update Cell Board Message tasks
 * @param params
 */
export const updateCellBoardMessageTasks = async (params: {
  id: string;
  tasks: string[];
}): Promise<CellBoardMessageDetailDto> => {
  const response = await axiosInstance.post(
    `/api/cellBoard/message/${params.id}/task`,
    params.tasks,
  );
  return response.data;
};

/**
 * API call to update Cell Board Message Link
 * @param link
 */
export const updateCellBoardMessageLink = async ({
  id,
  ...linkInfo
}: CellBoardMessageLinkUpdateInfo): Promise<CellBoardMessageLinkDto> => {
  const response = await axiosInstance.post(
    `/api/cellBoard/messageLink/${id}`,
    linkInfo,
  );
  return response.data;
};

/**
 * API call to add Cell Board Message Link
 * @param info
 */
export const addCellBoardMessageLink = async (
  info: CellBoardMessageLinkAddInfo,
): Promise<CellBoardMessageLinkDto> => {
  const response = await axiosInstance.post(
    `/api/cellBoard/message/${info.messageId}/link`,
    [
      {
        url: info.url,
        title: info.title,
      },
    ],
  );
  return response.data;
};

/**
 * API call to delete a Cell Board Message Link
 * @param id
 */
export const deleteCellBoardMessageLink = async (id: string) => {
  await axiosInstance.delete(`/api/cellBoard/messageLink/${id}`);
};

/**
 * API call to delete a Cell Board Message
 * @param id
 */
export const deleteCellBoardMessage = async (id: string) => {
  await axiosInstance.delete(`/api/cellBoard/message/${id}`);
};

/**
 * API call to add Attachments to Cell Board Message
 * @param params
 */
export const addAttachmentsToCellBoardMessage = async (params: {
  messageId: string;
  files: File[];
}): Promise<CellBoardMessageAttachmentDto> => {
  const formData = new FormData();
  params.files.forEach((f) => {
    formData.append('files', f);
  });
  const response = await axiosInstance.post<CellBoardMessageAttachmentDto>(
    `/api/cellBoard/message/${params.messageId}/attachment`,
    formData,
  );
  return response.data;
};

/**
 * API call to delete Attachment from Cell Board Message
 * @param id
 */
export const deleteAttachmentFromCellBoardMessage = async (id: string) => {
  await axiosInstance.delete(`/api/cellBoard/messageAttachment/${id}`);
};

/**
 * API call to get all active MCell Messages for an MCell
 * @param params
 */
export const getMCellMessages = async (params: {
  mCellId: string;
  shift: Shift;
}): Promise<CellBoardMCellMessageDetailDto[]> => {
  const response = await axiosInstance.get<CellBoardMCellMessageDetailDto[]>(
    '/api/cellBoard/mCellMessage',
    {
      params,
    },
  );
  return response.data;
};

/**
 * API call to get MCell Message by id
 * @param id
 */
export const getMCellMessageById = async (
  id: string,
): Promise<CellBoardMCellMessageDetailDto> => {
  const response = await axiosInstance.get(`/api/cellBoard/mCellMessage/${id}`);
  return response.data;
};

/**
 * API call to get all Cell Board Data for an MCell Shift combination
 * @param params
 */
export const getCellBoardData = async (params: {
  mCellId: string;
  shift: Shift;
}): Promise<CellBoardData> => {
  const response = await axiosInstance.get<CellBoardData>(
    '/api/cellBoard/data',
    {
      params,
    },
  );
  return response.data;
};

/**
 * API call to mark Cell Board MCell Message as read
 * @param id
 */
export const markMCellMessageRead = async (
  id: string,
): Promise<CellBoardMCellMessageDetailDto> => {
  const response = await axiosInstance.post<CellBoardMCellMessageDetailDto>(
    `/api/cellBoard/mCellMessage/${id}/read`,
  );
  return response.data;
};

/**
 * API call to mark Cell Board MCell Task as complete
 * @param id
 */
export const markMCellTaskComplete = async (
  id: string,
): Promise<CellBoardMCellTaskDto> => {
  const response = await axiosInstance.post(
    `/api/cellBoard/mCellTask/${id}/complete`,
  );
  return response.data;
};

/**
 * API call to mark Cell Board MCell Task as incomplete
 * @param id
 */
export const markMCellTaskIncomplete = async (
  id: string,
): Promise<CellBoardMCellTaskDto> => {
  const response = await axiosInstance.post(
    `/api/cellBoard/mCellTask/${id}/incomplete`,
  );
  return response.data;
};

/**
 * API call to get Cell Board MCell Task report data
 * @param filter
 */
export const getMCellTaskReportData = async (
  filter: CellBoardMCellTaskFilter,
): Promise<CellBoardMCellTaskReport[]> => {
  const response = await axiosInstance.get(`/api/cellBoard/mCellTaskReport`, {
    params: filter,
  });
  return response.data;
};

/**
 * API call to set notes on Cell Board MCell Task
 * @param params
 */
export const setNotesMCellTask = async (params: {
  id: string;
  notes: string;
}): Promise<CellBoardMCellTaskDto> => {
  const response = await axiosInstance.post(
    `/api/cellBoard/mCellTask/${params.id}/notes`,
    JSON.stringify(params.notes),
  );
  return response.data;
};

/**
 * API call to get Cell Board Config for an MCell Shift combination
 * @param params
 */
export const getCellBoardConfig = async (params: {
  mCellId: string;
  shift: Shift;
}): Promise<CellBoardConfig> => {
  const response = await axiosInstance.get<CellBoardConfig>(
    '/api/cellBoard/config',
    {
      params,
    },
  );
  return response.data;
};

/**
 * API call to update Cell Board Config
 * @param params
 */
export const updateCellBoardConfig = async (params: {
  id: string;
  config: CellBoardConfigInfo;
}): Promise<CellBoardConfig> => {
  const response = await axiosInstance.post(
    `/api/cellBoard/config/${params.id}`,
    params.config,
  );
  return response.data;
};

/**
 * API call to get the list of Cell Board Roster Inventory Center Configs
 */
export const getCellBoardRosterInventoryCenterConfigs = async (): Promise<
  CellBoardRosterInventoryCenterConfig[]
> => {
  const response = await axiosInstance.get<
    CellBoardRosterInventoryCenterConfig[]
  >('/api/cellBoard/rosterInventoryCenterConfig', {});
  return response.data;
};

/**
 * API call to set the Cell Board Roster config for an Inventory Center
 * @param config
 */
export const setCellBoardRosterInventoryCenterConfig = async (
  config: CellBoardRosterInventoryCenterConfig,
): Promise<CellBoardSafetyMessage> => {
  const response = await axiosInstance.post(
    '/api/cellBoard/rosterInventoryCenterConfig',
    config,
  );
  return response.data;
};

/**
 * API call to get the Cell Board Safety Message for an MCell Shift combination
 * @param params
 */
export const getCellBoardSafetyMessages = async (params: {
  inventoryCenterId: string;
  mCellId: string;
  mCellCollectionId: string;
  shifts: Shift[];
}): Promise<CellBoardSafetyMessage[]> => {
  const response = await axiosInstance.get<CellBoardSafetyMessage[]>(
    '/api/cellBoard/safetyMessageBulk',
    {
      params,
    },
  );
  return response.data;
};

/**
 * API call to set the Cell Board Safety Message for an MCell Shift combination
 * @param params
 */
export const setCellBoardSafetyMessages = async (params: {
  inventoryCenterId: string;
  mCellId: string;
  mCellCollectionId: string;
  shifts: Shift[];
  message: string;
}): Promise<CellBoardSafetyMessage[]> => {
  const response = await axiosInstance.post(
    `/api/cellBoard/safetyMessageBulk`,
    JSON.stringify(params.message),
    {
      params,
    },
  );
  return response.data;
};

/**
 * API call to delete a Cell Board Safety Message
 * @param safetyMessageId
 */
export const deleteCellBoardSafetyMessage = async (safetyMessageId: string) => {
  await axiosInstance.delete(`/api/cellBoard/safetyMessage/${safetyMessageId}`);
};

/**
 * API call to get the Cell Board Safety Topic for an Inventory Center
 * @params inventoryCenterId
 */
export const getCellBoardSafetyTopic = async (
  inventoryCenterId: string,
): Promise<CellBoardSafetyTopic> => {
  const response = await axiosInstance.get<CellBoardSafetyTopic>(
    '/api/cellBoard/safetyTopic',
    {
      params: {
        inventoryCenterId,
      },
    },
  );
  return response.data;
};

/**
 * API call to set the Cell Board Safety Topic for an Inventory Center
 * @param params
 */
export const setCellBoardSafetyTopic = async (params: {
  inventoryCenterId: string;
  file: File;
}): Promise<CellBoardSafetyTopic> => {
  const formData = new FormData();
  formData.append('file', params.file);
  const response = await axiosInstance.post(
    `/api/cellBoard/safetyTopic`,
    formData,
    {
      params,
    },
  );
  return response.data;
};

/**
 * API call to delete a Cell Board Safety Topic
 * @param safetyTopicId
 */
export const deleteCellBoardSafetyTopic = async (safetyTopicId: string) => {
  await axiosInstance.delete(`/api/cellBoard/safetyTopic/${safetyTopicId}`);
};

/**
 * API call to get the Cell Board Default Safety Message for an Inventory Center
 * @params inventoryCenterId
 */
export const getCellBoardDefaultSafetyMessage = async (
  inventoryCenterId: string,
): Promise<CellBoardDefaultSafetyMessage> => {
  const response = await axiosInstance.get<CellBoardDefaultSafetyMessage>(
    '/api/cellBoard/defaultSafetyMessage',
    {
      params: {
        inventoryCenterId,
      },
    },
  );
  return response.data;
};

/**
 * API call to set the Cell Board Default Safety Message for an Inventory Center
 * @param params
 */
export const setCellBoardDefaultSafetyMessage = async (params: {
  inventoryCenterId: string;
  message: string;
}): Promise<CellBoardDefaultSafetyMessage> => {
  const response = await axiosInstance.post(
    `/api/cellBoard/defaultSafetyMessage`,
    JSON.stringify(params.message),
    {
      params,
    },
  );
  return response.data;
};

/**
 * API call to delete a Cell Board Default Safety Message
 * @param defaultSafetyMessageId
 */
export const deleteCellBoardDefaultSafetyMessage = async (
  defaultSafetyMessageId: string,
) => {
  await axiosInstance.delete(
    `/api/cellBoard/defaultSafetyMessage/${defaultSafetyMessageId}`,
  );
};

/**
 * API call to get Cell Board Enabled MCell Infos
 * @param inventoryCenterId
 */
export const getCellBoardEnabledMCellInfos = async (
  inventoryCenterId: string,
): Promise<CellBoardEnabledMCellInfo[]> => {
  const response = await axiosInstance.get(`/api/cellBoard/enabledMCellInfo`, {
    params: { inventoryCenterId },
  });
  return response.data;
};

/**
 * API call to get a Cell Board Summary for an MCell and Shift
 * @param mCellId
 * @param shift
 */
export const getCellBoardSummary = async (
  mCellId: string,
  shift: Shift,
): Promise<CellBoardSummaryDto> => {
  const response = await axiosInstance.get(`/api/CellBoard/summary`, {
    params: { mCellId, shift },
  });
  return response.data;
};

/**
 * API call to get a filtered list of Cell Board Summaries
 * @param filter
 */
export const getCellBoardSummaries = async (
  filter: CellBoardSummaryFilter,
): Promise<CellBoardSummaryDto[]> => {
  const response = await axiosInstance.get(`/api/CellBoard/summaries`, {
    params: filter,
  });
  return response.data;
};

/**
 * API call to get a list of Cell Board Summary Histories
 * @param filter - filter parameters
 */
export const getCellBoardSummaryHistories = async (
  filter: CellBoardSummaryHistoryFilter,
): Promise<CellBoardSummaryHistoryDto[]> => {
  const response = await axiosInstance.get(
    `/api/CellBoard/summaries/histories`,
    {
      params: filter,
    },
  );
  return response.data;
};

/**
 * API call to update Hour By Hour Note
 * @param params
 */
export const setProductionMetricIntervalNote = async (params: {
  note: string;
  id: string;
}): Promise<ProductionMetricIntervalDto> => {
  const response = await axiosInstance.post(
    `/api/CellBoard/productionMetricInterval/${params.id}/note`,
    JSON.stringify(params.note),
  );
  return response.data;
};

/**
 * API call to update Hour By Hour Action
 * @param params
 */
export const setProductionMetricIntervalAction = async (params: {
  action: string;
  id: string;
}): Promise<ProductionMetricIntervalDto> => {
  const response = await axiosInstance.post(
    `/api/CellBoard/productionMetricInterval/${params.id}/action`,
    JSON.stringify(params.action),
  );
  return response.data;
};

/**
 * API call to get Production Metric Interval
 * @param filter
 */
export const getProductionMetricIntervals = async (
  filter: ProductionMetricIntervalFilter,
): Promise<ProductionMetricIntervalDto[]> => {
  const response = await axiosInstance.get(
    `/api/CellBoard/productionMetricInterval`,
    {
      params: filter,
    },
  );
  return response.data;
};

/**
 * API Call to get CellBoard Metric Histories Reports
 * @param filter
 */
export const getCellBoardMetricHistoriesReport = async (
  filter: CellBoardMetricReportFilter,
): Promise<CellBoardMetricReport[]> => {
  const response = await axiosInstance.get(
    `/api/CellBoard/cellBoardMetricReport`,
    {
      params: filter,
    },
  );
  return response.data;
};

/**
 * API call to get Production Metric
 * @param mCellId
 * @param shift
 */
export const getCellBoardProductionMetric = async (
  mCellId: string,
  shift: Shift,
): Promise<CellBoardProductionMetricDto> => {
  const response = await axiosInstance.get(`/api/CellBoard/productionMetric`, {
    params: { mCellId, shift },
  });
  return response.data;
};
