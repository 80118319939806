import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import {
  CELL_BOARD_ENABLED,
  SCORECARD_ENABLED,
  COATINGS_ENABLED,
  JOB_STATION_ASSIGNMENT_ENABLED,
  JOB_STATIONS_ENABLED,
  RESOURCE_TRACKING_ENABLED,
  SUPPORT_HIERARCHY_ENABLED,
  SCHEDULING_TOOL_ENABLED,
  LSW_ENABLED,
  UserSettingKey,
  M_CELL_STATUS_ENABLED,
} from '../features/profile/types';
import { IPermission } from '../auth/permissions';
import {
  CellBoardIcon,
  CoatingsIcon,
  CommentIcon,
  ConfigurationsIcon,
  CrewingGuideIcon,
  CrewingGuideXRefIcon,
  CrewingIcon,
  DashboardIcon,
  DevIcon,
  DotIcon,
  IssueIcon,
  FactoryAutomationIcon,
  HomeIcon,
  JobStationAssignmentIcon,
  JobStationIcon,
  KeyIcon,
  MCellAlertIcon,
  MCellCollectionIcon,
  MessageIcon,
  NotificationsIcon,
  ImageIcon,
  PollIcon,
  powerBiDashboardIcon,
  ReportIcon,
  ResourceTrackingIcon,
  ScoreboardIcon,
  ScorecardIcon,
  SupportHierarchyIcon,
  TimeTableIcon,
  PersonAndClockIcon,
  ApplicationEditIcon,
  FormatListChecksIcon,
  SchedulingToolIcon,
  SalesOrderIcon,
  ChangeLogIcon,
  EmployeesIcon,
  LaborWhatIfIcon,
  InboxIcon,
  MailIcon,
  OrderSearchIcon,
  EmployeeSearchIcon,
  EmailIcon,
  HelpIcon,
  CellLoginIcon,
  LswIcon,
  LmsIcon,
} from './icons';
import { useNavigate } from 'react-router-dom';
import qs from 'qs';
import { ProducingLocationMetricKey } from '../features/schedulingTool/types';
import { InventoryLocationIcon, MCellIcon } from '../features/settings/icons';

type Path = {
  absolute: string;
  relative: string;
  childPaths: { [key: string]: Path };
};

// See https://marvin.atlassian.net/wiki/spaces/RSTW/pages/2020343811/Adding+Navigation+Routes+To+CCC+Frontend
// on adding navigation routes

export const HOME = 'home';

export const CELL_LOGIN = 'cellLogin';

// Common
export const CONFIGURATION = 'configuration';
export const DETAIL = ':id/detail';
export const CREATE = 'create';
export const MESSAGE = 'message';
export const REPORT = 'report';
export const EDIT = ':id/edit';

// Crewing
export const CREWING = 'crewing';
export const CREWING_GUIDE_XREF = 'crewingGuideXRef';
export const CREWING_GUIDE = 'crewingGuide';
export const EMPLOYEE_SEARCH = 'employeeSearch';
export const MCELL_ALERT = 'mCellAlert';
export const MCELL_STATUS = 'mCellStatus';

// Cell Board
export const CELL_BOARD = 'cellBoard';
export const DELIVERY_DETAIL = 'delivery/detail';
export const PRODUCTION_DETAIL = 'production/detail';
export const QUALITY_DETAIL = 'quality/detail';
export const SAFETY_DETAIL = 'safety/detail';
export const HUDDLE = 'huddle';
export const SCORECARD = 'scorecard';
export const JOB_STATION_ASSIGNMENT = 'jobStationAssignments';
export const HOUR_BY_HOUR = 'hourByHour';
export const DOWNTIME = 'downtime';
export const MESSAGE_CREATE = `${MESSAGE}/${CREATE}`;
export const MESSAGE_DETAIL = `${MESSAGE}/${DETAIL}`;
export const MCELL_MESSAGE = 'mCellMessage';
export const MCELL_MESSAGE_DETAIL = `${MCELL_MESSAGE}/${DETAIL}`;
export const SAFETY_MESSAGE = 'safetyMessage';
export const SAFETY_TOPIC = 'safetyTopic';

// Issue Fast Path
export const REPORT_ISSUE = `${DOWNTIME}/${CREATE}`;

// Dashboard
export const DASHBOARD = 'dashboard';
export const POWERBI_REPORT = `powerbi/${REPORT}`;
export const POWERBI_DASHBOARD = `powerbi/${DASHBOARD}`;

// Coatings
export const COATINGS = 'coatings';
export const PROFILE_SEARCH = 'profileSearch';
export const PROFILE_DETAIL = `profile/${DETAIL}`;
export const PROFILE_EDIT = `profile/${EDIT}`;

// Support Hierarchy
export const SUPPORT_HIERARCHY = 'supportHierarchy';
export const HIERARCHY = 'hierarchy';
export const HIERARCHY_DETAIL = `${HIERARCHY}/${DETAIL}`;
export const HIERARCHY_CREATE = `${HIERARCHY}/${CREATE}`;
export const SUPPORT_GROUP_MEMBERS = `supportGroupMembers`;

// Scheduling Tool
export const SCHEDULING_TOOL = 'schedulingTool';
export const CAPACITY_UTILIZATION = 'capacityUtilization';
export const ORDER_SEARCH = 'orderSearch';
export const SALES_ORDERS = 'salesOrders';
export const CHANGE_LOGS = 'changeLogs';
export const EMPLOYEES = 'employees';
export const LABOR_WHAT_IF = 'laborWhatIf';

// LSW (Leader Standard Work)
export const LSW = 'lsw';
export const LSW_TASK = 'task';

// Resource Tracking
export const RESOURCE_TRACKING = 'resourceTracking';
export const INTEREST_PROFILE = 'interestProfile';

// Cell Board Summary
export const CELLBOARD_SUMMARY = 'cellboardSummary';

// Settings
export const SETTINGS = 'settings';

// Emails
export const EMAILS = 'emails';

// DEVELOPMENT
export const DEV = 'dev';
export const FEATURE_CONTROL = 'featureControl';
export const MANUAL_MCELL_LOGIN = 'manualMCellLogin';
export const ALERT_BANNER = 'alertBanner';
export const EMAIL_TESTING = 'emailTesting';

// Configuration
export const NOTIFICATION_SYSTEM = 'notificationSystem';
export const PERMISSIONS = 'permissions';
export const MANUFACTURING_LOCATION = 'manufacturingLocation';
export const MCELL_COLLECTION = 'mCellCollection';
export const MCELL_SETUP = 'mCellSetup';
export const FACTORY_AUTOMATION = 'factoryAutomation';
export const MCELL_COLLECTION_CREATE = `${MCELL_COLLECTION}/${CREATE}`;
export const MCELL_COLLECTION_DETAIL = `${MCELL_COLLECTION}/${DETAIL}`;
export const JOB_STATION = 'jobStation';
export const INVENTORY_CENTERS = 'inventoryCenters';
export const HELP_LINK = 'helpLink';
export const LMS = 'lms';

const paths: { [key: string]: Path } = {
  [HOME]: {
    absolute: `${HOME}`,
    relative: `${HOME}/*`,
    childPaths: {
      [CONFIGURATION]: {
        absolute: `/${HOME}/${CONFIGURATION}`,
        relative: CONFIGURATION,
        childPaths: {
          [CREATE]: {
            absolute: `/${HOME}/${CONFIGURATION}/${CREATE}`,
            relative: `${CONFIGURATION}/${CREATE}`,
            childPaths: {},
          },
          [DETAIL]: {
            absolute: `/${HOME}/${CONFIGURATION}/${DETAIL}`,
            relative: `${CONFIGURATION}/${DETAIL}`,
            childPaths: {},
          },
        },
      },
    },
  },
  [REPORT_ISSUE]: {
    absolute: `/${REPORT_ISSUE}`,
    relative: REPORT_ISSUE,
    childPaths: {
      [CONFIGURATION]: {
        absolute: `/${DOWNTIME}/${CONFIGURATION}`,
        relative: `${DOWNTIME}/${CONFIGURATION}`,
        childPaths: {},
      },
    },
  },
  [CELL_BOARD]: {
    absolute: `/${CELL_BOARD}`,
    relative: `${CELL_BOARD}/*`,
    childPaths: {
      [DELIVERY_DETAIL]: {
        absolute: `/${CELL_BOARD}/${DELIVERY_DETAIL}`,
        relative: DELIVERY_DETAIL,
        childPaths: {},
      },
      [PRODUCTION_DETAIL]: {
        absolute: `/${CELL_BOARD}/${PRODUCTION_DETAIL}`,
        relative: PRODUCTION_DETAIL,
        childPaths: {},
      },
      [QUALITY_DETAIL]: {
        absolute: `/${CELL_BOARD}/${QUALITY_DETAIL}`,
        relative: QUALITY_DETAIL,
        childPaths: {},
      },
      [SAFETY_DETAIL]: {
        absolute: `/${CELL_BOARD}/${SAFETY_DETAIL}`,
        relative: SAFETY_DETAIL,
        childPaths: {},
      },
      [HUDDLE]: {
        absolute: `/${CELL_BOARD}/${HUDDLE}`,
        relative: HUDDLE,
        childPaths: {},
      },
      [SCORECARD]: {
        absolute: `/${CELL_BOARD}/${SCORECARD}`,
        relative: SCORECARD,
        childPaths: {},
      },
      [JOB_STATION_ASSIGNMENT]: {
        absolute: `/${CELL_BOARD}/${JOB_STATION_ASSIGNMENT}`,
        relative: JOB_STATION_ASSIGNMENT,
        childPaths: {},
      },
      [HOUR_BY_HOUR]: {
        absolute: `/${CELL_BOARD}/${HOUR_BY_HOUR}`,
        relative: HOUR_BY_HOUR,
        childPaths: {},
      },
      [DOWNTIME]: {
        absolute: `/${CELL_BOARD}/${DOWNTIME}`,
        relative: DOWNTIME,
        childPaths: {
          [CREATE]: {
            absolute: `/${CELL_BOARD}/${DOWNTIME}/${CREATE}`,
            relative: `${DOWNTIME}/${CREATE}`,
            childPaths: {},
          },
          [DETAIL]: {
            absolute: `/${CELL_BOARD}/${DOWNTIME}/${DETAIL}`,
            relative: `${DOWNTIME}/${DETAIL}`,
            childPaths: {},
          },
          [CONFIGURATION]: {
            absolute: `/${CELL_BOARD}/${DOWNTIME}/${CONFIGURATION}`,
            relative: `${DOWNTIME}/${CONFIGURATION}`,
            childPaths: {},
          },
        },
      },
      [MESSAGE]: {
        absolute: `/${CELL_BOARD}/${MESSAGE}`,
        relative: MESSAGE,
        childPaths: {},
      },
      [MESSAGE_CREATE]: {
        absolute: `/${CELL_BOARD}/${MESSAGE_CREATE}`,
        relative: MESSAGE_CREATE,
        childPaths: {},
      },
      [MESSAGE_DETAIL]: {
        absolute: `/${CELL_BOARD}/${MESSAGE_DETAIL}`,
        relative: MESSAGE_DETAIL,
        childPaths: {},
      },
      [MCELL_MESSAGE]: {
        absolute: `/${CELL_BOARD}/${MCELL_MESSAGE}`,
        relative: MCELL_MESSAGE,
        childPaths: {},
      },
      [MCELL_MESSAGE_DETAIL]: {
        absolute: `/${CELL_BOARD}/${MCELL_MESSAGE_DETAIL}`,
        relative: MCELL_MESSAGE_DETAIL,
        childPaths: {},
      },
      [REPORT]: {
        absolute: `/${CELL_BOARD}/${REPORT}`,
        relative: REPORT,
        childPaths: {
          [DETAIL]: {
            absolute: `/${CELL_BOARD}/${REPORT}/${DETAIL}`,
            relative: DETAIL,
            childPaths: {},
          },
        },
      },
      [SAFETY_MESSAGE]: {
        absolute: `/${CELL_BOARD}/${SAFETY_MESSAGE}`,
        relative: SAFETY_MESSAGE,
        childPaths: {},
      },
      [SAFETY_TOPIC]: {
        absolute: `/${CELL_BOARD}/${SAFETY_TOPIC}`,
        relative: SAFETY_TOPIC,
        childPaths: {},
      },
      [CONFIGURATION]: {
        absolute: `/${CELL_BOARD}/${CONFIGURATION}`,
        relative: CONFIGURATION,
        childPaths: {},
      },
    },
  },
  [CELL_LOGIN]: {
    absolute: `/${CELL_LOGIN}`,
    relative: `${CELL_LOGIN}/*`,
    childPaths: {},
  },
  [CREWING]: {
    absolute: `/${CREWING}`,
    relative: `${CREWING}/*`,
    childPaths: {
      [CREWING_GUIDE]: {
        absolute: `/${CREWING}/${CREWING_GUIDE}`,
        relative: CREWING_GUIDE,
        childPaths: {},
      },
      [CREWING_GUIDE_XREF]: {
        absolute: `/${CREWING}/${CREWING_GUIDE_XREF}`,
        relative: CREWING_GUIDE_XREF,
        childPaths: {},
      },
      [EMPLOYEE_SEARCH]: {
        absolute: `/${CREWING}/${EMPLOYEE_SEARCH}`,
        relative: EMPLOYEE_SEARCH,
        childPaths: {},
      },
      [MCELL_ALERT]: {
        absolute: `/${CREWING}/${MCELL_ALERT}`,
        relative: MCELL_ALERT,
        childPaths: {},
      },
      [MCELL_STATUS]: {
        absolute: `/${CREWING}/${MCELL_STATUS}`,
        relative: MCELL_STATUS,
        childPaths: {},
      },
      [JOB_STATION_ASSIGNMENT]: {
        absolute: `/${CREWING}/${JOB_STATION_ASSIGNMENT}`,
        relative: JOB_STATION_ASSIGNMENT,
        childPaths: {},
      },
      [REPORT]: {
        absolute: `/${CREWING}/${REPORT}`,
        relative: REPORT,
        childPaths: {
          [DETAIL]: {
            absolute: `/${CREWING}/${REPORT}/${DETAIL}`,
            relative: DETAIL,
            childPaths: {},
          },
        },
      },
      [CONFIGURATION]: {
        absolute: `/${CREWING}/${CONFIGURATION}`,
        relative: CONFIGURATION,
        childPaths: {},
      },
    },
  },
  [COATINGS]: {
    absolute: `/${COATINGS}`,
    relative: `${COATINGS}/*`,
    childPaths: {
      [PROFILE_SEARCH]: {
        absolute: `/${COATINGS}`,
        relative: PROFILE_SEARCH,
        childPaths: {},
      },
      [PROFILE_DETAIL]: {
        absolute: `/${COATINGS}/${PROFILE_DETAIL}`,
        relative: PROFILE_DETAIL,
        childPaths: {},
      },
      [PROFILE_EDIT]: {
        absolute: `/${COATINGS}/${PROFILE_EDIT}`,
        relative: PROFILE_EDIT,
        childPaths: {},
      },
    },
  },
  [CELLBOARD_SUMMARY]: {
    absolute: `/${CELLBOARD_SUMMARY}`,
    relative: CELLBOARD_SUMMARY,
    childPaths: {},
  },
  [DASHBOARD]: {
    absolute: `/${DASHBOARD}`,
    relative: `${DASHBOARD}/*`,
    childPaths: {
      [DETAIL]: {
        absolute: `/${DASHBOARD}/${DETAIL}`,
        relative: DETAIL,
        childPaths: {},
      },
      [POWERBI_REPORT]: {
        absolute: `/${DASHBOARD}/${POWERBI_REPORT}`,
        relative: POWERBI_REPORT,
        childPaths: {},
      },
      [POWERBI_DASHBOARD]: {
        absolute: `/${DASHBOARD}/${POWERBI_DASHBOARD}`,
        relative: POWERBI_DASHBOARD,
        childPaths: {},
      },
    },
  },

  [EMAILS]: {
    absolute: `/${EMAILS}`,
    relative: `${EMAILS}/*`,
    childPaths: {},
  },

  [SUPPORT_HIERARCHY]: {
    absolute: `/${SUPPORT_HIERARCHY}`,
    relative: `${SUPPORT_HIERARCHY}/*`,
    childPaths: {
      [HIERARCHY]: {
        absolute: `/${SUPPORT_HIERARCHY}/${HIERARCHY}`,
        relative: HIERARCHY,
        childPaths: {},
      },
      [HIERARCHY_DETAIL]: {
        absolute: `/${SUPPORT_HIERARCHY}/${HIERARCHY_DETAIL}`,
        relative: HIERARCHY_DETAIL,
        childPaths: {},
      },
      [HIERARCHY_CREATE]: {
        absolute: `/${SUPPORT_HIERARCHY}/${HIERARCHY_CREATE}`,
        relative: HIERARCHY_CREATE,
        childPaths: {},
      },
      [SUPPORT_GROUP_MEMBERS]: {
        absolute: `/${SUPPORT_HIERARCHY}/${SUPPORT_GROUP_MEMBERS}`,
        relative: SUPPORT_GROUP_MEMBERS,
        childPaths: {},
      },
      [CONFIGURATION]: {
        absolute: `/${SUPPORT_HIERARCHY}/${CONFIGURATION}`,
        relative: CONFIGURATION,
        childPaths: {},
      },
    },
  },
  [SCHEDULING_TOOL]: {
    absolute: `/${SCHEDULING_TOOL}`,
    relative: `${SCHEDULING_TOOL}/*`,
    childPaths: {
      [CAPACITY_UTILIZATION]: {
        absolute: `/${SCHEDULING_TOOL}/${CAPACITY_UTILIZATION}`,
        relative: CAPACITY_UTILIZATION,
        childPaths: {},
      },
      [ORDER_SEARCH]: {
        absolute: `/${SCHEDULING_TOOL}/${ORDER_SEARCH}`,
        relative: ORDER_SEARCH,
        childPaths: {},
      },
      [SALES_ORDERS]: {
        absolute: `/${SCHEDULING_TOOL}/${SALES_ORDERS}`,
        relative: SALES_ORDERS,
        childPaths: {},
      },
      [CHANGE_LOGS]: {
        absolute: `/${SCHEDULING_TOOL}/${CHANGE_LOGS}`,
        relative: CHANGE_LOGS,
        childPaths: {},
      },
      [EMPLOYEES]: {
        absolute: `/${SCHEDULING_TOOL}/${EMPLOYEES}`,
        relative: EMPLOYEES,
        childPaths: {},
      },
      [LABOR_WHAT_IF]: {
        absolute: `/${SCHEDULING_TOOL}/${LABOR_WHAT_IF}`,
        relative: LABOR_WHAT_IF,
        childPaths: {},
      },
      [CONFIGURATION]: {
        absolute: `/${SCHEDULING_TOOL}/${CONFIGURATION}`,
        relative: CONFIGURATION,
        childPaths: {},
      },
    },
  },
  [LSW]: {
    absolute: `/${LSW}`,
    relative: `${LSW}/*`,
    childPaths: {
      [LSW_TASK]: {
        absolute: `/${LSW}/${LSW_TASK}`,
        relative: LSW_TASK,
        childPaths: {},
      },
      [REPORT]: {
        absolute: `/${LSW}/${REPORT}`,
        relative: REPORT,
        childPaths: {},
      },
      [CONFIGURATION]: {
        absolute: `/${LSW}/${CONFIGURATION}`,
        relative: CONFIGURATION,
        childPaths: {},
      },
    },
  },
  [REPORT]: {
    absolute: `/${REPORT}`,
    relative: `${REPORT}/*`,
    childPaths: {
      [DETAIL]: {
        absolute: `/${REPORT}/${DETAIL}`,
        relative: DETAIL,
        childPaths: {},
      },
    },
  },
  [RESOURCE_TRACKING]: {
    absolute: `/${RESOURCE_TRACKING}`,
    relative: `${RESOURCE_TRACKING}/*`,
    childPaths: {
      [CONFIGURATION]: {
        absolute: `/${RESOURCE_TRACKING}/${CONFIGURATION}`,
        relative: CONFIGURATION,
        childPaths: {},
      },
      [INTEREST_PROFILE]: {
        absolute: `/${RESOURCE_TRACKING}/${INTEREST_PROFILE}`,
        relative: `${INTEREST_PROFILE}/*`,
        childPaths: {
          [CREATE]: {
            absolute: `/${RESOURCE_TRACKING}/${INTEREST_PROFILE}/${CREATE}`,
            relative: CREATE,
            childPaths: {},
          },
        },
      },
    },
  },
  [SETTINGS]: {
    absolute: `/${SETTINGS}`,
    relative: `${SETTINGS}/*`,
    childPaths: {},
  },
  [CONFIGURATION]: {
    absolute: `/${CONFIGURATION}`,
    relative: `${CONFIGURATION}/*`,
    childPaths: {
      [MANUFACTURING_LOCATION]: {
        absolute: `/${CONFIGURATION}/${MANUFACTURING_LOCATION}`,
        relative: MANUFACTURING_LOCATION,
        childPaths: {
          [INVENTORY_CENTERS]: {
            absolute: `/${CONFIGURATION}/${MANUFACTURING_LOCATION}/${INVENTORY_CENTERS}`,
            relative: INVENTORY_CENTERS,
            childPaths: {},
          },
          [MCELL_COLLECTION]: {
            absolute: `/${CONFIGURATION}/${MANUFACTURING_LOCATION}/${MCELL_COLLECTION}`,
            relative: MCELL_COLLECTION,
            childPaths: {},
          },
          [MCELL_COLLECTION_CREATE]: {
            absolute: `/${CONFIGURATION}/${MANUFACTURING_LOCATION}/${MCELL_COLLECTION_CREATE}`,
            relative: MCELL_COLLECTION_CREATE,
            childPaths: {},
          },
          [MCELL_COLLECTION_DETAIL]: {
            absolute: `/${CONFIGURATION}/${MANUFACTURING_LOCATION}/${MCELL_COLLECTION_DETAIL}`,
            relative: MCELL_COLLECTION_DETAIL,
            childPaths: {},
          },
          [MCELL_SETUP]: {
            absolute: `/${CONFIGURATION}/${MANUFACTURING_LOCATION}/${MCELL_SETUP}`,
            relative: MCELL_SETUP,
            childPaths: {},
          },
        },
      },
      [NOTIFICATION_SYSTEM]: {
        absolute: `/${CONFIGURATION}/${NOTIFICATION_SYSTEM}`,
        relative: NOTIFICATION_SYSTEM,
        childPaths: {},
      },
      [HELP_LINK]: {
        absolute: `/${CONFIGURATION}/${HELP_LINK}`,
        relative: `${HELP_LINK}/*`,
        childPaths: {},
      },
      [LMS]: {
        absolute: `/${CONFIGURATION}/${LMS}`,
        relative: `${LMS}/*`,
        childPaths: {},
      },
      [FACTORY_AUTOMATION]: {
        absolute: `/${CONFIGURATION}/${FACTORY_AUTOMATION}`,
        relative: FACTORY_AUTOMATION,
        childPaths: {},
      },
      [PERMISSIONS]: {
        absolute: `/${CONFIGURATION}/${PERMISSIONS}`,
        relative: PERMISSIONS,
        childPaths: {},
      },
      [JOB_STATION]: {
        absolute: `/${CONFIGURATION}/${JOB_STATION}`,
        relative: JOB_STATION,
        childPaths: {},
      },
    },
  },
  [DEV]: {
    absolute: `/${DEV}`,
    relative: `${DEV}/*`,
    childPaths: {
      [FEATURE_CONTROL]: {
        absolute: `/${DEV}/${FEATURE_CONTROL}`,
        relative: FEATURE_CONTROL,
        childPaths: {},
      },
      [MANUAL_MCELL_LOGIN]: {
        absolute: `/${DEV}/${MANUAL_MCELL_LOGIN}`,
        relative: MANUAL_MCELL_LOGIN,
        childPaths: {},
      },
      [ALERT_BANNER]: {
        absolute: `/${DEV}/${ALERT_BANNER}`,
        relative: ALERT_BANNER,
        childPaths: {},
      },
      [EMAIL_TESTING]: {
        absolute: `/${DEV}/${EMAIL_TESTING}`,
        relative: EMAIL_TESTING,
        childPaths: {},
      },
    },
  },
};

export const getRelativeNavigationPaths = () => {
  return {
    homePath: paths[HOME].relative,
    homeConfigurationPath: paths[HOME].childPaths[CONFIGURATION].relative,
    homeLinkCreatePath:
      paths[HOME].childPaths[CONFIGURATION].childPaths[CREATE].relative,
    homeLinkDetailEditPath:
      paths[HOME].childPaths[CONFIGURATION].childPaths[DETAIL].relative,
    reportIssuePath: paths[REPORT_ISSUE].relative,
    issuePath: paths[CELL_BOARD].childPaths[DOWNTIME].relative,
    issueConfigurationAltPath:
      paths[REPORT_ISSUE].childPaths[CONFIGURATION].relative,
    issueCreatePath:
      paths[CELL_BOARD].childPaths[DOWNTIME].childPaths[CREATE].relative,
    issueDetailPath:
      paths[CELL_BOARD].childPaths[DOWNTIME].childPaths[DETAIL].relative,
    issueConfigurationPath:
      paths[CELL_BOARD].childPaths[DOWNTIME].childPaths[CONFIGURATION].relative,
    dashboardPath: paths[DASHBOARD].relative,
    dashboardDetailPath: paths[DASHBOARD].childPaths[DETAIL].relative,
    powerBiReportPath: paths[DASHBOARD].childPaths[POWERBI_REPORT].relative,
    powerBiDashboardPath:
      paths[DASHBOARD].childPaths[POWERBI_DASHBOARD].relative,
    crewingPath: paths[CREWING].relative,
    crewingGuideXRefPath:
      paths[CREWING].childPaths[CREWING_GUIDE_XREF].relative,
    crewingGuidePath: paths[CREWING].childPaths[CREWING_GUIDE].relative,
    crewingEmployeeSearchPath:
      paths[CREWING].childPaths[EMPLOYEE_SEARCH].relative,
    crewingMCellStatusesPath: paths[CREWING].childPaths[MCELL_STATUS].relative,
    crewingMCellAlertsPath: paths[CREWING].childPaths[MCELL_ALERT].relative,
    crewingJobStationAssignmentsPath:
      paths[CREWING].childPaths[JOB_STATION_ASSIGNMENT].relative,
    crewingConfigurationPath: paths[CREWING].childPaths[CONFIGURATION].relative,
    crewingReportPath: paths[CREWING].childPaths[REPORT].relative,
    crewingReportDetailPath:
      paths[CREWING].childPaths[REPORT].childPaths[DETAIL].relative,
    cellBoardPath: paths[CELL_BOARD].relative,
    cellBoardQualityDetailPath:
      paths[CELL_BOARD].childPaths[QUALITY_DETAIL].relative,
    cellBoardDeliveryDetailPath:
      paths[CELL_BOARD].childPaths[DELIVERY_DETAIL].relative,
    cellBoardProductionDetailPath:
      paths[CELL_BOARD].childPaths[PRODUCTION_DETAIL].relative,
    cellBoardSafetyDetailPath:
      paths[CELL_BOARD].childPaths[SAFETY_DETAIL].relative,
    cellBoardHuddlePath: paths[CELL_BOARD].childPaths[HUDDLE].relative,
    cellBoardScorecardPath: paths[CELL_BOARD].childPaths[SCORECARD].relative,
    cellBoardJobStationAssignmentPath:
      paths[CELL_BOARD].childPaths[JOB_STATION_ASSIGNMENT].relative,
    cellBoardHourByHourPath:
      paths[CELL_BOARD].childPaths[HOUR_BY_HOUR].relative,
    cellBoardSafetyTopicPath:
      paths[CELL_BOARD].childPaths[SAFETY_TOPIC].relative,
    cellBoardSafetyMessagePath:
      paths[CELL_BOARD].childPaths[SAFETY_MESSAGE].relative,
    cellBoardMessagePath: paths[CELL_BOARD].childPaths[MESSAGE].relative,
    cellBoardMessageCreatePath:
      paths[CELL_BOARD].childPaths[MESSAGE_CREATE].relative,
    cellBoardMessageDetailPath:
      paths[CELL_BOARD].childPaths[MESSAGE_DETAIL].relative,
    cellBoardMCellMessagePath:
      paths[CELL_BOARD].childPaths[MCELL_MESSAGE].relative,
    cellBoardMCellMessageDetailPath:
      paths[CELL_BOARD].childPaths[MCELL_MESSAGE_DETAIL].relative,
    cellBoardReportPath: paths[CELL_BOARD].childPaths[REPORT].relative,
    cellBoardReportDetailPath:
      paths[CELL_BOARD].childPaths[REPORT].childPaths[DETAIL].relative,
    cellBoardConfigurationPath:
      paths[CELL_BOARD].childPaths[CONFIGURATION].relative,
    coatingsPath: paths[COATINGS].relative,
    coatingsProfileEditPath: paths[COATINGS].childPaths[PROFILE_EDIT].relative,
    coatingsProfileDetailPath:
      paths[COATINGS].childPaths[PROFILE_DETAIL].relative,
    coatingsProfileSearchPath:
      paths[COATINGS].childPaths[PROFILE_SEARCH].relative,
    supportHierarchyPath: paths[SUPPORT_HIERARCHY].relative,
    supportHierarchyHierarchyPath:
      paths[SUPPORT_HIERARCHY].childPaths[HIERARCHY].relative,
    supportHierarchyHierarchyDetailPath:
      paths[SUPPORT_HIERARCHY].childPaths[HIERARCHY_DETAIL].relative,
    supportHierarchyHierarchyCreatePath:
      paths[SUPPORT_HIERARCHY].childPaths[HIERARCHY_CREATE].relative,
    supportHierarchyConfigurationsPath:
      paths[SUPPORT_HIERARCHY].childPaths[CONFIGURATION].relative,
    supportHierarchySupportGroupMembersPath:
      paths[SUPPORT_HIERARCHY].childPaths[SUPPORT_GROUP_MEMBERS].relative,
    schedulingToolPath: paths[SCHEDULING_TOOL].relative,
    schedulingToolCapacityUtilizationPath:
      paths[SCHEDULING_TOOL].childPaths[CAPACITY_UTILIZATION].relative,
    schedulingToolOrderSearchPath:
      paths[SCHEDULING_TOOL].childPaths[ORDER_SEARCH].relative,
    schedulingToolSalesOrderPath:
      paths[SCHEDULING_TOOL].childPaths[SALES_ORDERS].relative,
    schedulingToolChangeLogPath:
      paths[SCHEDULING_TOOL].childPaths[CHANGE_LOGS].relative,
    schedulingToolEmployeesPath:
      paths[SCHEDULING_TOOL].childPaths[EMPLOYEES].relative,
    schedulingToolLaborWhatIfPath:
      paths[SCHEDULING_TOOL].childPaths[LABOR_WHAT_IF].relative,
    schedulingToolConfigurationsPath:
      paths[SCHEDULING_TOOL].childPaths[CONFIGURATION].relative,
    lswPath: paths[LSW].relative,
    lswTaskPath: paths[LSW].childPaths[LSW_TASK].relative,
    lswReportPath: paths[LSW].childPaths[REPORT].relative,
    lswConfigurationsPath: paths[LSW].childPaths[CONFIGURATION].relative,
    reportDetailPath: paths[REPORT].childPaths[DETAIL].relative,
    resourceTrackingPath: paths[RESOURCE_TRACKING].relative,
    resourceTrackingInterestProfilePath:
      paths[RESOURCE_TRACKING].childPaths[INTEREST_PROFILE].relative,
    resourceTrackingInterestProfileCreatePath:
      paths[RESOURCE_TRACKING].childPaths[INTEREST_PROFILE].childPaths[CREATE]
        .relative,
    resourceTrackingConfigurationPath:
      paths[RESOURCE_TRACKING].childPaths[CONFIGURATION].relative,
    cellboardSummaryPath: paths[CELLBOARD_SUMMARY].relative,
    settingsPath: paths[SETTINGS].relative,
    configurationPath: paths[CONFIGURATION].relative,
    configurationManufacturingLocationPath:
      paths[CONFIGURATION].childPaths[MANUFACTURING_LOCATION].relative,
    configurationManufacturingLocationInventoryCentersPath:
      paths[CONFIGURATION].childPaths[MANUFACTURING_LOCATION].childPaths[
        INVENTORY_CENTERS
      ].relative,
    configurationMCellCollectionPath:
      paths[CONFIGURATION].childPaths[MANUFACTURING_LOCATION].childPaths[
        MCELL_COLLECTION
      ].relative,
    configurationMCellCollectionCreatePath:
      paths[CONFIGURATION].childPaths[MANUFACTURING_LOCATION].childPaths[
        MCELL_COLLECTION_CREATE
      ].relative,
    configurationMCellCollectionDetailPath:
      paths[CONFIGURATION].childPaths[MANUFACTURING_LOCATION].childPaths[
        MCELL_COLLECTION_DETAIL
      ].relative,
    configurationMCellSetupPath:
      paths[CONFIGURATION].childPaths[MANUFACTURING_LOCATION].childPaths[
        MCELL_SETUP
      ].relative,
    configurationNotificationSystemPath:
      paths[CONFIGURATION].childPaths[NOTIFICATION_SYSTEM].relative,
    configurationFactoryAutomationPath:
      paths[CONFIGURATION].childPaths[FACTORY_AUTOMATION].relative,
    configurationPermissionsPath:
      paths[CONFIGURATION].childPaths[PERMISSIONS].relative,
    configurationJobStationPath:
      paths[CONFIGURATION].childPaths[JOB_STATION].relative,
    configurationHelpLinkPath:
      paths[CONFIGURATION].childPaths[HELP_LINK].relative,
    configurationLmsPath: paths[CONFIGURATION].childPaths[LMS].relative,
    emailsPath: paths[EMAILS].relative,
    cellLoginPath: paths[CELL_LOGIN].relative,
    devPath: paths[DEV].relative,
    devFeatureControlPath: paths[DEV].childPaths[FEATURE_CONTROL].relative,
    devManualMCellLoginPath: paths[DEV].childPaths[MANUAL_MCELL_LOGIN].relative,
    devAlertBannerPath: paths[DEV].childPaths[ALERT_BANNER].relative,
    devEmailTestingPath: paths[DEV].childPaths[EMAIL_TESTING].relative,
  };
};

export const getAbsoluteNavigationPaths = () => {
  return {
    homePath: paths[HOME].absolute,
    homeConfigurationPath: paths[HOME].childPaths[CONFIGURATION].absolute,
    homeLinkCreatePath:
      paths[HOME].childPaths[CONFIGURATION].childPaths[CREATE].absolute,
    homeLinkDetailEditPath:
      paths[HOME].childPaths[CONFIGURATION].childPaths[DETAIL].absolute,
    reportIssuePath: paths[REPORT_ISSUE].absolute,
    issuePath: paths[CELL_BOARD].childPaths[DOWNTIME].absolute,
    emailsPath: paths[EMAILS].absolute,
    cellLoginPath: paths[CELL_LOGIN].absolute,
    issueConfigurationAltPath:
      paths[REPORT_ISSUE].childPaths[CONFIGURATION].absolute,
    issueCreatePath:
      paths[CELL_BOARD].childPaths[DOWNTIME].childPaths[CREATE].absolute,
    issueDetailPath:
      paths[CELL_BOARD].childPaths[DOWNTIME].childPaths[DETAIL].absolute,
    issueConfigurationPath:
      paths[CELL_BOARD].childPaths[DOWNTIME].childPaths[CONFIGURATION].absolute,
    dashboardPath: paths[DASHBOARD].absolute,
    dashboardDetailPath: paths[DASHBOARD].childPaths[DETAIL].absolute,
    powerBiDashboardPath:
      paths[DASHBOARD].childPaths[POWERBI_DASHBOARD].absolute,
    powerBiReportPath: paths[DASHBOARD].childPaths[POWERBI_REPORT].absolute,
    crewingPath: paths[CREWING].absolute,
    crewingGuideXRefPath:
      paths[CREWING].childPaths[CREWING_GUIDE_XREF].absolute,
    crewingGuidePath: paths[CREWING].childPaths[CREWING_GUIDE].absolute,
    crewingEmployeeSearchPath:
      paths[CREWING].childPaths[EMPLOYEE_SEARCH].absolute,
    crewingMCellStatusesPath: paths[CREWING].childPaths[MCELL_STATUS].absolute,
    crewingMCellAlertsPath: paths[CREWING].childPaths[MCELL_ALERT].absolute,
    crewingJobStationAssignmentsPath:
      paths[CREWING].childPaths[JOB_STATION_ASSIGNMENT].absolute,
    crewingConfigurationPath: paths[CREWING].childPaths[CONFIGURATION].absolute,
    crewingReportPath: paths[CREWING].childPaths[REPORT].absolute,
    crewingReportDetailPath:
      paths[CREWING].childPaths[REPORT].childPaths[DETAIL].absolute,
    cellBoardPath: paths[CELL_BOARD].absolute,
    cellBoardQualityDetailPath:
      paths[CELL_BOARD].childPaths[QUALITY_DETAIL].absolute,
    cellBoardDeliveryDetailPath:
      paths[CELL_BOARD].childPaths[DELIVERY_DETAIL].absolute,
    cellBoardProductionDetailPath:
      paths[CELL_BOARD].childPaths[PRODUCTION_DETAIL].absolute,
    cellBoardSafetyDetailPath:
      paths[CELL_BOARD].childPaths[SAFETY_DETAIL].absolute,
    cellBoardHuddlePath: paths[CELL_BOARD].childPaths[HUDDLE].absolute,
    cellBoardScorecardPath: paths[CELL_BOARD].childPaths[SCORECARD].absolute,
    cellBoardJobStationAssignmentPath:
      paths[CELL_BOARD].childPaths[JOB_STATION_ASSIGNMENT].absolute,
    cellBoardHourByHourPath:
      paths[CELL_BOARD].childPaths[HOUR_BY_HOUR].absolute,
    cellBoardSafetyTopicPath:
      paths[CELL_BOARD].childPaths[SAFETY_TOPIC].absolute,
    cellBoardSafetyMessagePath:
      paths[CELL_BOARD].childPaths[SAFETY_MESSAGE].absolute,
    cellBoardMessagePath: paths[CELL_BOARD].childPaths[MESSAGE].absolute,
    cellBoardMessageCreatePath:
      paths[CELL_BOARD].childPaths[MESSAGE_CREATE].absolute,
    cellBoardMessageDetailPath:
      paths[CELL_BOARD].childPaths[MESSAGE_DETAIL].absolute,
    cellBoardMCellMessagePath:
      paths[CELL_BOARD].childPaths[MCELL_MESSAGE].absolute,
    cellBoardMCellMessageDetailPath:
      paths[CELL_BOARD].childPaths[MCELL_MESSAGE_DETAIL].absolute,
    cellBoardReportPath: paths[CELL_BOARD].childPaths[REPORT].absolute,
    cellBoardReportDetailPath:
      paths[CELL_BOARD].childPaths[REPORT].childPaths[DETAIL].absolute,
    cellBoardConfigurationPath:
      paths[CELL_BOARD].childPaths[CONFIGURATION].absolute,
    coatingsPath: paths[COATINGS].absolute,
    coatingsProfileEditPath: paths[COATINGS].childPaths[PROFILE_EDIT].absolute,
    coatingsProfileDetailPath:
      paths[COATINGS].childPaths[PROFILE_DETAIL].absolute,
    coatingsProfileSearchPath:
      paths[COATINGS].childPaths[PROFILE_SEARCH].absolute,
    cellboardSummaryPath: paths[CELLBOARD_SUMMARY].absolute,
    supportHierarchyPath: paths[SUPPORT_HIERARCHY].absolute,
    supportHierarchyHierarchyPath:
      paths[SUPPORT_HIERARCHY].childPaths[HIERARCHY].absolute,
    supportHierarchyHierarchyDetailPath:
      paths[SUPPORT_HIERARCHY].childPaths[HIERARCHY_DETAIL].absolute,
    supportHierarchyHierarchyCreatePath:
      paths[SUPPORT_HIERARCHY].childPaths[HIERARCHY_CREATE].absolute,
    supportHierarchyConfigurationsPath:
      paths[SUPPORT_HIERARCHY].childPaths[CONFIGURATION].absolute,
    supportHierarchySupportGroupMembersPath:
      paths[SUPPORT_HIERARCHY].childPaths[SUPPORT_GROUP_MEMBERS].absolute,
    schedulingToolPath: paths[SCHEDULING_TOOL].absolute,
    schedulingToolCapacityUtilizationPath:
      paths[SCHEDULING_TOOL].childPaths[CAPACITY_UTILIZATION].absolute,
    schedulingToolOrderSearchPath:
      paths[SCHEDULING_TOOL].childPaths[ORDER_SEARCH].absolute,
    schedulingToolSalesOrderPath:
      paths[SCHEDULING_TOOL].childPaths[SALES_ORDERS].absolute,
    schedulingToolChangeLogPath:
      paths[SCHEDULING_TOOL].childPaths[CHANGE_LOGS].absolute,
    schedulingToolEmployeesPath:
      paths[SCHEDULING_TOOL].childPaths[EMPLOYEES].absolute,
    schedulingToolLaborWhatIfPath:
      paths[SCHEDULING_TOOL].childPaths[LABOR_WHAT_IF].absolute,
    schedulingToolConfigurationsPath:
      paths[SCHEDULING_TOOL].childPaths[CONFIGURATION].absolute,
    lswPath: paths[LSW].absolute,
    lswTaskPath: paths[LSW].childPaths[LSW_TASK].absolute,
    lswReportPath: paths[LSW].childPaths[REPORT].absolute,
    lswConfigurationsPath: paths[LSW].childPaths[CONFIGURATION].absolute,
    reportDetailPath: paths[REPORT].childPaths[DETAIL].absolute,
    resourceTrackingPath: paths[RESOURCE_TRACKING].absolute,
    resourceTrackingInterestProfilePath:
      paths[RESOURCE_TRACKING].childPaths[INTEREST_PROFILE].absolute,
    resourceTrackingInterestProfileCreatePath:
      paths[RESOURCE_TRACKING].childPaths[INTEREST_PROFILE].childPaths[CREATE]
        .absolute,
    resourceTrackingConfigurationPath:
      paths[RESOURCE_TRACKING].childPaths[CONFIGURATION].absolute,
    settingsPath: paths[SETTINGS].absolute,
    configurationPath: paths[CONFIGURATION].absolute,
    configurationManufacturingLocationPath:
      paths[CONFIGURATION].childPaths[MANUFACTURING_LOCATION].absolute,
    configurationManufacturingLocationInventoryCentersPath:
      paths[CONFIGURATION].childPaths[MANUFACTURING_LOCATION].childPaths[
        INVENTORY_CENTERS
      ].absolute,
    configurationMCellCollectionPath:
      paths[CONFIGURATION].childPaths[MANUFACTURING_LOCATION].childPaths[
        MCELL_COLLECTION
      ].absolute,
    configurationMCellCollectionCreatePath:
      paths[CONFIGURATION].childPaths[MANUFACTURING_LOCATION].childPaths[
        MCELL_COLLECTION_CREATE
      ].absolute,
    configurationMCellCollectionDetailPath:
      paths[CONFIGURATION].childPaths[MANUFACTURING_LOCATION].childPaths[
        MCELL_COLLECTION_DETAIL
      ].absolute,
    configurationMCellSetupPath:
      paths[CONFIGURATION].childPaths[MANUFACTURING_LOCATION].childPaths[
        MCELL_SETUP
      ].absolute,
    configurationNotificationSystemPath:
      paths[CONFIGURATION].childPaths[NOTIFICATION_SYSTEM].absolute,
    configurationHelpLinkPath:
      paths[CONFIGURATION].childPaths[HELP_LINK].absolute,
    configurationLmsPath: paths[CONFIGURATION].childPaths[LMS].absolute,
    configurationFactoryAutomationPath:
      paths[CONFIGURATION].childPaths[FACTORY_AUTOMATION].absolute,
    configurationPermissionsPath:
      paths[CONFIGURATION].childPaths[PERMISSIONS].absolute,
    configurationJobStationPath:
      paths[CONFIGURATION].childPaths[JOB_STATION].absolute,
    devPath: paths[DEV].absolute,
    devFeatureControlPath: paths[DEV].childPaths[FEATURE_CONTROL].absolute,
    devManualMCellLoginPath: paths[DEV].childPaths[MANUAL_MCELL_LOGIN].absolute,
    devAlertBannerPath: paths[DEV].childPaths[ALERT_BANNER].absolute,
    devEmailTestingPath: paths[DEV].childPaths[EMAIL_TESTING].absolute,
  };
};

export const useNavigation = () => {
  const navigate = useNavigate();
  const {
    homeConfigurationPath,
    homeLinkCreatePath,
    homeLinkDetailEditPath,
    issuePath,
    issueCreatePath,
    issueDetailPath,
    cellBoardPath,
    cellBoardQualityDetailPath,
    cellBoardProductionDetailPath,
    cellBoardDeliveryDetailPath,
    cellBoardSafetyDetailPath,
    cellBoardHuddlePath,
    cellBoardMCellMessageDetailPath,
    cellBoardMessagePath,
    cellBoardMessageCreatePath,
    cellBoardMessageDetailPath,
    dashboardPath,
    dashboardDetailPath,
    coatingsProfileSearchPath,
    coatingsProfileDetailPath,
    coatingsProfileEditPath,
    supportHierarchyHierarchyPath,
    supportHierarchyHierarchyDetailPath,
    supportHierarchyHierarchyCreatePath,
    resourceTrackingInterestProfilePath,
    resourceTrackingInterestProfileCreatePath,
    settingsPath,
    schedulingToolCapacityUtilizationPath,
    schedulingToolChangeLogPath,
    schedulingToolSalesOrderPath,
    configurationMCellCollectionPath,
    configurationMCellCollectionCreatePath,
    configurationMCellCollectionDetailPath,
    lswConfigurationsPath,
  } = getAbsoluteNavigationPaths();

  return {
    navigateTo: (path: string) => navigate(path),
    navigateBack: () => navigate(-1),
    navigateForward: () => navigate(1),
    navigateToHomeConfiguration: () => navigate(homeConfigurationPath),
    navigateToHomeLinkCreate: () => navigate(homeLinkCreatePath),
    navigateToHomeLinkDetailEdit: (id: string) =>
      navigate(homeLinkDetailEditPath.replace(/:id/, id)),
    navigateToIssue: () => navigate(issuePath),
    navigateToIssueCreate: () => navigate(issueCreatePath),
    navigateToIssueDetail: (id: string) =>
      navigate(issueDetailPath.replace(/:id/, id)),
    navigateToCellBoard: (
      mCellId?: string,
      inventoryCenterId?: string,
      shift?: string,
    ) => {
      const queryParams: {
        mCellId?: string;
        inventoryCenterId?: string;
        shift?: string;
      } = {};
      if (mCellId) queryParams.mCellId = mCellId;
      if (inventoryCenterId) queryParams.inventoryCenterId = inventoryCenterId;
      if (shift) queryParams.shift = shift;

      const queryString = qs.stringify(queryParams, { addQueryPrefix: true });
      navigate(cellBoardPath + queryString);
    },
    navigateToCellBoardQualityDetail: (replace: boolean = false) =>
      navigate(cellBoardQualityDetailPath, { replace }),
    navigateToCellBoardProductionDetail: (replace: boolean = false) =>
      navigate(cellBoardProductionDetailPath, { replace }),
    navigateToCellBoardDeliveryDetail: (replace: boolean = false) =>
      navigate(cellBoardDeliveryDetailPath, { replace }),
    navigateToCellBoardSafetyDetail: (replace: boolean = false) =>
      navigate(cellBoardSafetyDetailPath, { replace }),
    navigateToHuddle: () => navigate(cellBoardHuddlePath),
    navigateToMCellMessageDetail: (id: string) =>
      navigate(cellBoardMCellMessageDetailPath.replace(/:id/, id)),
    navigateToCellBoardMessage: () => navigate(cellBoardMessagePath),
    navigateToCellBoardMessageCreate: () =>
      navigate(cellBoardMessageCreatePath),
    navigateToCellBoardMessageDetail: (id: string) =>
      navigate(cellBoardMessageDetailPath.replace(/:id/, id)),
    navigateToDashboard: () => navigate(dashboardPath),
    navigateToDashboardDetail: (id: string) =>
      navigate(dashboardDetailPath.replace(/:id/, id)),
    navigateToProfileSearch: () => navigate(coatingsProfileSearchPath),
    navigateToProfileDetail: (id: string) =>
      navigate(coatingsProfileDetailPath.replace(/:id/, id)),
    navigateToProfileEdit: (id: string) =>
      navigate(coatingsProfileEditPath.replace(/:id/, id)),
    navigateToHierarchy: () => navigate(supportHierarchyHierarchyPath),
    navigateToHierarchyDetail: (id: string) =>
      navigate(supportHierarchyHierarchyDetailPath.replace(/:id/, id)),
    navigateToHierarchyCreate: () =>
      navigate(supportHierarchyHierarchyCreatePath),
    navigateToInterestProfile: () =>
      navigate(resourceTrackingInterestProfilePath),
    navigateToInterestProfileCreate: () =>
      navigate(resourceTrackingInterestProfileCreatePath),
    navigateToSettings: () => navigate(settingsPath),
    navigateToMCellCollection: () => navigate(configurationMCellCollectionPath),
    navigateToMCellCollectionCreate: () =>
      navigate(configurationMCellCollectionCreatePath),
    navigateToMCellCollectionDetail: (id: string) =>
      navigate(configurationMCellCollectionDetailPath.replace(/:id/, id)),
    navigateToSchedulingToolCapacityUtilization: (
      metric: ProducingLocationMetricKey = 'laborUtilizationPercent',
    ) =>
      navigate(
        schedulingToolCapacityUtilizationPath +
          qs.stringify({ metric }, { addQueryPrefix: true }),
      ),
    navigateToSchedulingToolSalesOrders: (
      inventoryCenterId: string,
      producingLocation: string,
      productionWeek: string,
    ) =>
      navigate(
        schedulingToolSalesOrderPath +
          qs.stringify(
            { inventoryCenterId, producingLocation, productionWeek },
            { addQueryPrefix: true },
          ),
      ),
    navigateToSchedulingToolChangeLog: () =>
      navigate(schedulingToolChangeLogPath),
    navigateToLswCreate: () => navigate(lswConfigurationsPath),
  };
};

const absolutePaths = getAbsoluteNavigationPaths();
export const navigationItems: NavigationItem[] = [
  {
    labelKey: 'home',
    path: absolutePaths.homePath,
    icon: HomeIcon,
    children: [
      {
        labelKey: 'configurations',
        path: absolutePaths.homeConfigurationPath,
        icon: ConfigurationsIcon,
        permissions: ['Home.Config'],
      },
    ],
  },
  {
    labelKey: 'reportIssue',
    path: absolutePaths.reportIssuePath,
    icon: IssueIcon,
    children: [
      {
        labelKey: 'configurations',
        path: absolutePaths.issueConfigurationAltPath,
        icon: ConfigurationsIcon,
        permissions: ['Downtime.ReasonSupportGroupRead', 'Downtime.ReasonEdit'],
      },
    ],
  },
  {
    labelKey: 'cellBoard',
    path: absolutePaths.cellBoardPath,
    icon: CellBoardIcon,
    enabledKey: CELL_BOARD_ENABLED,
    children: [
      {
        labelKey: 'scorecard',
        path: absolutePaths.cellBoardScorecardPath,
        icon: ScorecardIcon,
        enabledKey: SCORECARD_ENABLED,
      },
      {
        labelKey: 'hourByHour',
        path: absolutePaths.cellBoardHourByHourPath,
        icon: TimeTableIcon,
      },
      {
        labelKey: 'issues',
        path: absolutePaths.issuePath,
        icon: IssueIcon,
      },
      {
        labelKey: 'assignments',
        path: absolutePaths.cellBoardJobStationAssignmentPath,
        icon: JobStationAssignmentIcon,
        enabledKey: JOB_STATION_ASSIGNMENT_ENABLED,
      },
      {
        labelKey: 'inbox',
        path: absolutePaths.cellBoardMCellMessagePath,
        icon: InboxIcon,
      },
      {
        labelKey: 'messaging',
        path: absolutePaths.cellBoardMessagePath,
        icon: MessageIcon,
        permissions: ['CellBoard.MessageEdit'],
      },
      {
        labelKey: 'safetyMessage',
        path: absolutePaths.cellBoardSafetyMessagePath,
        icon: CommentIcon,
      },
      {
        labelKey: 'safetyTopic',
        path: absolutePaths.cellBoardSafetyTopicPath,
        icon: ImageIcon,
        permissions: ['CellBoard.SafetyTopicUpdate'],
      },
      {
        labelKey: 'reports',
        path: absolutePaths.cellBoardReportPath,
        icon: ReportIcon,
        permissions: ['CellBoard.ReportRead'],
      },
      {
        labelKey: 'configurations',
        path: absolutePaths.cellBoardConfigurationPath,
        icon: ConfigurationsIcon,
        permissions: [
          'CellBoard.ConfigUpdate',
          'CellBoard.WorkCellConfigUpdate',
          'CellBoard.RosterConfigUpdate',
          'Downtime.ReasonSupportGroupRead',
          'Downtime.ReasonEdit',
        ],
      },
    ],
  },
  {
    labelKey: 'cellLogin',
    path: absolutePaths.cellLoginPath,
    icon: CellLoginIcon,
  },
  {
    labelKey: 'crewing',
    path: absolutePaths.crewingPath,
    icon: CrewingIcon,
    permissions: ['ResourceTracking.CapacityRead'],
    children: [
      {
        labelKey: 'crewingGuide',
        path: absolutePaths.crewingGuidePath,
        icon: CrewingGuideIcon,
      },
      {
        labelKey: 'crewingGuideXRef',
        path: absolutePaths.crewingGuideXRefPath,
        icon: CrewingGuideXRefIcon,
      },
      {
        labelKey: 'employeeSearch',
        path: absolutePaths.crewingEmployeeSearchPath,
        icon: EmployeeSearchIcon,
      },
      {
        labelKey: 'workCellStatuses',
        path: absolutePaths.crewingMCellStatusesPath,
        icon: DotIcon,
        permissions: ['ResourceTracking.WorkCellStatusRead'],
        enabledKey: M_CELL_STATUS_ENABLED,
      },
      {
        labelKey: 'workCellAlerts',
        path: absolutePaths.crewingMCellAlertsPath,
        icon: MCellAlertIcon,
        enabledKey: M_CELL_STATUS_ENABLED,
      },
      {
        labelKey: 'assignments',
        path: absolutePaths.crewingJobStationAssignmentsPath,
        icon: JobStationAssignmentIcon,
        enabledKey: JOB_STATION_ASSIGNMENT_ENABLED,
      },
      {
        labelKey: 'reports',
        path: absolutePaths.crewingReportPath,
        icon: ReportIcon,
      },
      {
        labelKey: 'configurations',
        path: absolutePaths.crewingConfigurationPath,
        icon: ConfigurationsIcon,
        permissions: [
          'WorkCellAlert.PointUpdate',
          'ResourceTracking.DemandMgmtStatusConfigUpdate',
          'ManufacturingLocation.KeyWorkCellUpdate',
          'ResourceTracking.WorkCellCapacityUpdate',
        ],
      },
    ],
  },
  {
    labelKey: 'coatings',
    path: absolutePaths.coatingsProfileSearchPath,
    icon: CoatingsIcon,
    enabledKey: COATINGS_ENABLED,
  },
  {
    labelKey: 'scoreboard',
    path: absolutePaths.cellboardSummaryPath,
    icon: ScoreboardIcon,
  },
  {
    labelKey: 'dashboard',
    path: absolutePaths.dashboardPath,
    icon: DashboardIcon,
    permissions: ['Dashboard.Read'],
    children: [
      {
        labelKey: 'powerBiReport',
        path: absolutePaths.powerBiReportPath,
        icon: PollIcon,
        permissions: ['Developer.All'],
      },
      {
        labelKey: 'dashboard',
        path: absolutePaths.powerBiDashboardPath,
        icon: powerBiDashboardIcon,
        permissions: ['Developer.All'],
      },
    ],
  },
  {
    labelKey: 'supportHierarchy',
    path: absolutePaths.supportHierarchyPath,
    icon: SupportHierarchyIcon,
    enabledKey: SUPPORT_HIERARCHY_ENABLED,
    permissions: ['SupportHierarchy.HierarchyRead'],
    children: [
      {
        labelKey: 'members',
        path: absolutePaths.supportHierarchySupportGroupMembersPath,
        icon: CrewingIcon,
      },
      {
        labelKey: 'configurations',
        path: absolutePaths.supportHierarchyConfigurationsPath,
        icon: ConfigurationsIcon,
        permissions: [
          'SupportHierarchy.SupportGroupEdit',
          'SupportHierarchy.EscalationOverrideRead',
        ],
      },
    ],
  },
  {
    labelKey: 'schedulingTool',
    path: absolutePaths.schedulingToolPath,
    icon: SchedulingToolIcon,
    enabledKey: SCHEDULING_TOOL_ENABLED,
    permissions: ['SchedulingTool.Read'],
    children: [
      {
        labelKey: 'orderSearch',
        path: absolutePaths.schedulingToolOrderSearchPath,
        icon: OrderSearchIcon,
      },
      {
        labelKey: 'salesOrders',
        path: absolutePaths.schedulingToolSalesOrderPath,
        icon: SalesOrderIcon,
      },
      {
        labelKey: 'changeLogs',
        path: absolutePaths.schedulingToolChangeLogPath,
        icon: ChangeLogIcon,
        permissions: ['SchedulingTool.ScheduleEdit'],
      },
      {
        labelKey: 'employees',
        path: absolutePaths.schedulingToolEmployeesPath,
        icon: EmployeesIcon,
      },
      {
        labelKey: 'laborWhatIf',
        path: absolutePaths.schedulingToolLaborWhatIfPath,
        icon: LaborWhatIfIcon,
      },
      {
        labelKey: 'configurations',
        path: absolutePaths.schedulingToolConfigurationsPath,
        icon: ConfigurationsIcon,
        permissions: ['SchedulingTool.ConfigEdit'],
      },
    ],
  },
  {
    labelKey: 'lsw',
    path: absolutePaths.lswPath,
    icon: LswIcon,
    enabledKey: LSW_ENABLED,
    children: [
      {
        labelKey: 'reports',
        path: absolutePaths.lswReportPath,
        icon: ReportIcon,
      },
      {
        labelKey: 'configurations',
        path: absolutePaths.lswConfigurationsPath,
        icon: ConfigurationsIcon,
      },
    ],
  },
  {
    labelKey: 'myAccount',
    path: absolutePaths.resourceTrackingInterestProfilePath,
    icon: ResourceTrackingIcon,
    enabledKey: RESOURCE_TRACKING_ENABLED,
    children: [
      {
        labelKey: 'configurations',
        path: absolutePaths.resourceTrackingConfigurationPath,
        icon: ConfigurationsIcon,
        enabledKey: RESOURCE_TRACKING_ENABLED,
        permissions: [
          'ResourceTracking.DepartmentOfInterestUpdate',
          'ResourceTracking.EmployeeInterestEdit',
        ],
      },
    ],
  },

  {
    labelKey: 'emails',
    path: absolutePaths.emailsPath,
    icon: EmailIcon,
    permissions: ['Email.Read'],
  },

  {
    labelKey: 'globalConfigs',
    path: absolutePaths.configurationPath,
    icon: ConfigurationsIcon,
    children: [
      {
        labelKey: 'inventoryCenters',
        path: absolutePaths.configurationManufacturingLocationInventoryCentersPath,
        icon: InventoryLocationIcon,
        permissions: ['ManufacturingLocation.InventoryCenterEdit'],
      },
      {
        labelKey: 'workCellGroups',
        path: absolutePaths.configurationMCellCollectionPath,
        icon: MCellCollectionIcon,
      },
      {
        labelKey: 'workCells',
        path: absolutePaths.configurationMCellSetupPath,
        icon: MCellIcon,
        permissions: ['ManufacturingLocation.MCellEdit'],
      },
      {
        labelKey: 'jobStations',
        path: absolutePaths.configurationJobStationPath,
        icon: JobStationIcon,
        enabledKey: JOB_STATIONS_ENABLED,
        permissions: [
          'ResourceTracking.JobStationEdit',
          'ResourceTracking.EquipmentEdit',
          'MCellEmployee.JobFunctionEdit',
        ],
      },
      {
        labelKey: 'helpLinks',
        path: absolutePaths.configurationHelpLinkPath,
        icon: HelpIcon,
        permissions: ['HelpLink.Edit'],
      },
      {
        labelKey: 'lms',
        path: absolutePaths.configurationLmsPath,
        icon: LmsIcon,
        permissions: ['Lms.ConfigEdit'],
      },
      {
        labelKey: 'notificationSystem',
        path: absolutePaths.configurationNotificationSystemPath,
        icon: NotificationsIcon,
        permissions: ['ManufacturingLocation.NotificationSystemUpdate'],
      },
      {
        labelKey: 'factoryAutomation',
        path: absolutePaths.configurationFactoryAutomationPath,
        icon: FactoryAutomationIcon,
        permissions: ['FactoryAutomation.OverheadDoorSignalRead'],
      },
      {
        labelKey: 'permissions',
        path: absolutePaths.configurationPermissionsPath,
        icon: KeyIcon,
        permissions: ['Settings.PermissionsRead'],
      },
    ],
  },
  {
    labelKey: 'developer',
    path: absolutePaths.devPath,
    icon: DevIcon,
    permissions: ['Developer.All'],
    children: [
      {
        labelKey: 'featureControl',
        path: absolutePaths.devFeatureControlPath,
        icon: FormatListChecksIcon,
        permissions: ['Developer.All'],
      },
      {
        labelKey: 'manualLogin',
        path: absolutePaths.devManualMCellLoginPath,
        icon: PersonAndClockIcon,
        permissions: ['Developer.All'],
      },
      {
        labelKey: 'alertBanner',
        path: absolutePaths.devAlertBannerPath,
        icon: ApplicationEditIcon,
        permissions: ['Developer.All'],
      },
      {
        labelKey: 'emailTesting',
        path: absolutePaths.devEmailTestingPath,
        icon: MailIcon,
        permissions: ['Developer.All'],
      },
    ],
  },
];

export type NavigationItem = {
  labelKey: string;
  path: string;
  icon: OverridableComponent<SvgIconTypeMap>;
  enabledKey?: UserSettingKey;
  permissions?: IPermission[];
  children?: NavigationChildItem[];
};

export type NavigationChildItem = Omit<NavigationItem, 'children'>;
