export type IRole =
  | 'Admin'
  | 'CoatingsAdmin'
  | 'SchedulingToolAdmin'
  | 'Developer'
  | 'Lead'
  | 'Manager'
  | 'Supervisor';

export type IPermission =
  | 'CellBoard.ConfigUpdate'
  | 'CellBoard.DefaultSafetyMessageEdit'
  | 'CellBoard.MessageEdit'
  | 'CellBoard.SafetyMessageEdit'
  | 'CellBoard.MessageReadGlobal'
  | 'CellBoard.ReportRead'
  | 'CellBoard.RosterConfigUpdate'
  | 'CellBoard.SafetyTopicUpdate'
  | 'CellBoard.WorkCellConfigUpdate'
  | 'Crewing.EmployeeRefresh'
  | 'Coatings.ProfileEdit'
  | 'Dashboard.CopyToPrivate'
  | 'Dashboard.Create'
  | 'Dashboard.GlobalDelete'
  | 'Dashboard.GlobalEditLayout'
  | 'Dashboard.GlobalRename'
  | 'Dashboard.GlobalWidgetEdit'
  | 'Dashboard.Read'
  | 'Dashboard.SetGlobal'
  | 'Dashboard.SetPrivate'
  | 'Developer.All'
  | 'Downtime.Delete'
  | 'Downtime.ReasonEdit'
  | 'Downtime.ReasonSupportGroupEdit'
  | 'Downtime.ReasonSupportGroupRead'
  | 'Email.Read'
  | 'FactoryAutomation.OverheadDoorSignalEdit'
  | 'FactoryAutomation.OverheadDoorSignalRead'
  | 'Home.Config'
  | 'HelpLink.Edit'
  | 'Lsw.ConfigEdit'
  | 'Lms.ConfigEdit'
  | 'Machine.ConfigEdit'
  | 'ManufacturingLocation.GlobalWorkCellGroupDelete'
  | 'ManufacturingLocation.GlobalWorkCellGroupUpdate'
  | 'ManufacturingLocation.KeyWorkCellUpdate'
  | 'ManufacturingLocation.MCellEdit'
  | 'ManufacturingLocation.NotificationSystemUpdate'
  | 'ManufacturingLocation.WorkCellGroupCopyToPrivate'
  | 'ManufacturingLocation.WorkCellGroupCreate'
  | 'ManufacturingLocation.WorkCellGroupSetGlobalPrivate'
  | 'ManufacturingLocation.WorkCellGroupViewAll'
  | 'ManufacturingLocation.InventoryCenterEdit'
  | 'MCellEmployee.JobFunctionEdit'
  | 'Report.Export'
  | 'Report.Read'
  | 'ResourceTracking.CapacityRead'
  | 'ResourceTracking.DemandMgmtStatusConfigUpdate'
  | 'ResourceTracking.DepartmentOfInterestUpdate'
  | 'ResourceTracking.EmployeeInterestEdit'
  | 'ResourceTracking.EquipmentEdit'
  | 'ResourceTracking.JobStationEdit'
  | 'ResourceTracking.JobStationView'
  | 'ResourceTracking.WorkCellCapacityUpdate'
  | 'ResourceTracking.WorkCellStatusDemandManagementUpdate'
  | 'ResourceTracking.WorkCellStatusOperationsUpdate'
  | 'ResourceTracking.WorkCellStatusRead'
  | 'SchedulingTool.ConfigEdit'
  | 'SchedulingTool.Read'
  | 'SchedulingTool.ScheduleEdit'
  | 'SchedulingTool.ChangeLogViewGlobal'
  | 'Settings.PermissionsRead'
  | 'SupportHierarchy.EscalationOverrideEdit'
  | 'SupportHierarchy.EscalationOverrideRead'
  | 'SupportHierarchy.HierarchyEdit'
  | 'SupportHierarchy.HierarchyRead'
  | 'SupportHierarchy.SupportGroupEdit'
  | 'WorkCellAlert.PointUpdate';

export type IPermissionMap = {
  [key in IPermission]: IRole[];
};
const AtLeastLead: IRole[] = [
  'Lead',
  'Supervisor',
  'Manager',
  'Admin',
  'Developer',
];
const AtLeastSupervisor: IRole[] = [
  'Supervisor',
  'Manager',
  'Admin',
  'Developer',
];
const AtLeastManager: IRole[] = ['Manager', 'Admin', 'Developer'];
const AtLeastAdmin: IRole[] = ['Admin', 'Developer'];

// Special Permissions
const CoatingsAdmin: IRole[] = ['CoatingsAdmin', ...AtLeastAdmin];
const SchedulingToolAdmin: IRole[] = ['SchedulingToolAdmin', ...AtLeastAdmin];
const Developer: IRole[] = ['Developer'];

/**
 * Mapping of application permissions to roles
 */
export const permissionMap: IPermissionMap = {
  'CellBoard.ConfigUpdate': AtLeastAdmin,
  'CellBoard.DefaultSafetyMessageEdit': AtLeastAdmin,
  'CellBoard.MessageEdit': AtLeastLead,
  'CellBoard.SafetyMessageEdit': AtLeastSupervisor,
  'CellBoard.MessageReadGlobal': AtLeastLead,
  'CellBoard.ReportRead': AtLeastLead,
  'CellBoard.RosterConfigUpdate': AtLeastAdmin,
  'CellBoard.SafetyTopicUpdate': AtLeastManager,
  'CellBoard.WorkCellConfigUpdate': AtLeastAdmin,
  'Crewing.EmployeeRefresh': AtLeastSupervisor,
  'Coatings.ProfileEdit': CoatingsAdmin,
  'Dashboard.CopyToPrivate': AtLeastLead,
  'Dashboard.Create': AtLeastLead,
  'Dashboard.GlobalDelete': AtLeastAdmin,
  'Dashboard.GlobalEditLayout': AtLeastAdmin,
  'Dashboard.GlobalRename': AtLeastAdmin,
  'Dashboard.GlobalWidgetEdit': AtLeastAdmin,
  'Dashboard.Read': AtLeastLead,
  'Dashboard.SetGlobal': AtLeastAdmin,
  'Dashboard.SetPrivate': AtLeastAdmin,
  'Developer.All': Developer,
  'Downtime.Delete': AtLeastSupervisor,
  'Downtime.ReasonEdit': AtLeastAdmin,
  'Downtime.ReasonSupportGroupEdit': AtLeastAdmin,
  'Downtime.ReasonSupportGroupRead': AtLeastAdmin,
  'Email.Read': AtLeastAdmin,
  'FactoryAutomation.OverheadDoorSignalEdit': AtLeastAdmin,
  'FactoryAutomation.OverheadDoorSignalRead': AtLeastAdmin,
  'Home.Config': AtLeastLead,
  'Lsw.ConfigEdit': AtLeastSupervisor,
  'Lms.ConfigEdit': AtLeastAdmin,
  'HelpLink.Edit': AtLeastAdmin,
  'Machine.ConfigEdit': AtLeastAdmin,
  'ManufacturingLocation.GlobalWorkCellGroupDelete': AtLeastAdmin,
  'ManufacturingLocation.GlobalWorkCellGroupUpdate': AtLeastAdmin,
  'ManufacturingLocation.KeyWorkCellUpdate': AtLeastAdmin,
  'ManufacturingLocation.MCellEdit': AtLeastAdmin,
  'ManufacturingLocation.NotificationSystemUpdate': AtLeastAdmin,
  'ManufacturingLocation.WorkCellGroupCopyToPrivate': AtLeastLead,
  'ManufacturingLocation.WorkCellGroupCreate': AtLeastLead,
  'ManufacturingLocation.WorkCellGroupSetGlobalPrivate': AtLeastAdmin,
  'ManufacturingLocation.WorkCellGroupViewAll': AtLeastLead,
  'ManufacturingLocation.InventoryCenterEdit': AtLeastAdmin,
  'MCellEmployee.JobFunctionEdit': AtLeastAdmin,
  'Report.Export': AtLeastSupervisor,
  'Report.Read': AtLeastSupervisor,
  'ResourceTracking.CapacityRead': AtLeastLead,
  'ResourceTracking.DemandMgmtStatusConfigUpdate': AtLeastAdmin,
  'ResourceTracking.DepartmentOfInterestUpdate': AtLeastAdmin,
  'ResourceTracking.EmployeeInterestEdit': AtLeastAdmin,
  'ResourceTracking.EquipmentEdit': AtLeastAdmin,
  'ResourceTracking.JobStationEdit': AtLeastManager,
  'ResourceTracking.JobStationView': AtLeastLead,
  'ResourceTracking.WorkCellCapacityUpdate': AtLeastAdmin,
  'ResourceTracking.WorkCellStatusDemandManagementUpdate': AtLeastSupervisor,
  'ResourceTracking.WorkCellStatusOperationsUpdate': AtLeastSupervisor,
  'ResourceTracking.WorkCellStatusRead': AtLeastLead,
  'SchedulingTool.ConfigEdit': SchedulingToolAdmin,
  'SchedulingTool.Read': AtLeastSupervisor,
  'SchedulingTool.ScheduleEdit': SchedulingToolAdmin,
  'SchedulingTool.ChangeLogViewGlobal': SchedulingToolAdmin,
  'Settings.PermissionsRead': AtLeastAdmin,
  'SupportHierarchy.EscalationOverrideEdit': AtLeastAdmin,
  'SupportHierarchy.EscalationOverrideRead': AtLeastAdmin,
  'SupportHierarchy.HierarchyEdit': AtLeastAdmin,
  'SupportHierarchy.HierarchyRead': AtLeastLead,
  'SupportHierarchy.SupportGroupEdit': AtLeastAdmin,
  'WorkCellAlert.PointUpdate': AtLeastAdmin,
};
